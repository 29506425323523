import * as axios from "axios";
const METABASE_URL = "http://159.203.45.72:3001/getMetabaseQuestion";
const MERCHANT_API_URL = "https://api.swervepm.com";
const IMAGE_SERVER_URL = "https://images.swervepm.com";

export const getMetaBaseQuestion = async (params) => {

    const headers = {
        'Accept': 'application/json'
    };

    return axios
        .post(METABASE_URL , params, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getNewMetaBaseQuestion = async (params) => {

    const headers = {
        'Accept': 'application/json'
    };

    return axios
        .post(`${MERCHANT_API_URL}/targetingEngineGraph` , params, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const login = async(data)=>{


    const headers = {
        'Accept': 'application/json'
    };

    return axios
        .post(`${MERCHANT_API_URL}/login` , data, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

}
export const getMerchantList = async()=>{


    return axios
        .get(`${MERCHANT_API_URL}/getMerchantList` )
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

}

export const getCategories = async()=>{


    return axios
        .get(`${MERCHANT_API_URL}/getCategories` )
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

}

export const getCityList = async(data)=>{


    const headers = {
        'Accept': 'application/json'
    };

    return axios
        .post(`${MERCHANT_API_URL}/getCityList` , data, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

}

export const getCampaignList = async()=>{

    return axios
        .get(`${MERCHANT_API_URL}/getCampaignList` )
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

}

export const addCampaign = async(params,details) =>{

    const headers = {
        'Accept': 'application/json'
    };

    return axios
        .post(`${MERCHANT_API_URL}/createCampaign` , {details,params}, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

}

export const getImgUrl = async(img) =>{

    const headers = {
        'Accept': img.type
    };

    return axios
        .post(`${IMAGE_SERVER_URL}/uploadImage` , {img}, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

}

export const createMerchant = async (params) => {

    const headers = {
        'Accept': 'application/json'
    };

    return axios
        .post(`${MERCHANT_API_URL}/createMerchant` , params, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};
