import React,{Component} from "react"
import Input from "../Form/Controls/Input";
import DropDown from "../Form/Controls/DropDown";
import {connect} from "react-redux";
import {createMerchant, getCategories} from "../../api/api";


class AddMerchant extends Component{

    constructor(props){
        super(props);

        this.state ={
            merchantName: "",
            categories:[],
            categoryList:[],
            keywords:[""],
            imgUrl:"",
            refresh:true
        };
        this.handleNameChange = this.handleNameChange.bind(this);
       // this.onImageChange = this.onImageChange.bind(this);
        this.genCategoryBoxes = this.genCategoryBoxes.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.genKeywordBoxes = this.genKeywordBoxes.bind(this);
        this.addKeyword = this.addKeyword.bind(this);
        this.addMerchant = this.addMerchant.bind(this);
        this.renderLocation = this.renderLocation.bind(this);
        this.getCategoryLists();

    }

    async getCategoryLists(){
        const categoryList = await getCategories();
        console.log(categoryList);
        const firstElement = {category:categoryList[0].id, subCategory:categoryList[0].subCategories[0].id};

        this.setState({categoryList, categories:[firstElement]})


    }

    handleNameChange(event)
    {
        const text = event.target.value;
        this.setState({merchantName: text});
    }

    genImageUploadBox()
    {
        // <ImageUploading
        //     value={this.state.images}
        //     onChange={this.onImageChange}
        //     dataURLKey="data_url"
        // >
        // {({
        //     imageList,
        //     onImageUpload,
        //     onImageRemoveAll,
        //     onImageUpdate,
        //     onImageRemove,
        //     isDragging,
        //     dragProps,
        // }) => (
        //     <div className="upload__image-wrapper">
        //         <button
        //             style={isDragging ? { color: 'red' } : undefined}
        //             onClick={onImageUpload}
        //             {...dragProps}
        //         >
        //             Click or Drop here
        //         </button>
        //         &nbsp;
        //         <button onClick={onImageRemoveAll}>Remove all images</button>
        //         {imageList.map((image, index) => (
        //             <div key={index} className="image-item">
        //                 <img src={image['data_url']} alt="" width="100" />
        //                 <div className="image-item__btn-wrapper">
        //                     <button onClick={() => onImageUpdate(index)}>Update</button>
        //                     <button onClick={() => onImageRemove(index)}>Remove</button>
        //                 </div>
        //             </div>
        //         ))}
        //     </div>
        // )}
        // </ImageUploading>
    }

    // async onImageChange(imageList){
    //     const url = await getImgUrl(imageList[0]);
    //     this.setState({imgUrl:url})
    //   };

    
    buildDropDown(labelText, options, value, onChange, key)
    {
        console.log(options)
        return (
            <div>
                <label>{labelText}</label>
                <select
                    onChange={(e) => onChange(e.target.value, key)}
                    value={value}
                    className={"col-lg-4"}
                >
                    {options.map((option) => {

                        return (
                            <option key={option.id} value={option.id}>{option.category ?? option.subcategory}</option>
                        );
                    })}
                </select>
            </div>
        );
    }


    genCategoryBoxes()
    {
        return this.state.categories.map( (value, key) => {
            let subCategories = [];
            for(const category of this.state.categoryList)
            {
                if(category.id == value.category)
                { 
                    console.log(category)

                    subCategories = category.subCategories;
                }
            }

            const updateCategory = (value, key) => {
                console.dir(value)
                let list = this.state.categories;
                list[key] = {...list[key], category:value} 
                this.setState({categories:list, refresh:!this.state.refresh})
            }

            const updateSubCategory = (value, key) => {
                let list = this.state.categories;
                list[key] = {...list[key], subCategory:value} 
                this.setState({categories:list, refresh:!this.state.refresh})
            }

            return <div className="row">
            {this.buildDropDown("Category",this.state.categoryList, value.category, updateCategory, key)}
            {this.buildDropDown("SubCategory",subCategories, value.subCategory, updateSubCategory, key)}
            </div>

        });

      
    }

    addCategory()
    {
        let categories = this.state.categories;
        const categoryList = this.state.categoryList;
        categories.push({category:categoryList[0].id, subCategory:categoryList[0].subCategories[0].id})
        this.setState({categories:categories, refresh:!this.state.refresh})
    }

    genKeywordBoxes()
    {
        return this.state.keywords.map((value, key) =>
        {

            const updateKeyword = (value, key) =>
            {
                let keywords = this.state.keywords;
                keywords[key] = value;
                this.setState({keywords, refresh:!this.state.refresh})
            }

            return <div className="col-lg-6 mg-t-10 mg-b-20 mg-lg-t-0">
                <Input
                type={"text"}
                labelText={`Keyword`}
                placeholder={`Keyword`}
                className={"form-control"}
                number={"false"}
                parentname={"AddMerchantForm"}
                name={`keyword${key}`}
                newValue={value}
                onChange={(e) =>{updateKeyword(e.target.value,key)}}
                />
            </div>
        });
    }

    addKeyword()
    {
        let keywords = this.state.keywords;
        keywords.push("");
        this.setState({keywords, refresh:!this.state.refresh});
    }

    async addMerchant()
    {
        const categories = this.state.categories.reduce((list, value) =>{ return [...list, value.subCategory] }, [])
        const params = 
        {
            name:this.state.merchantName,
            img:"test.png",
            keywords:this.state.keywords,
            categories
        }
        await createMerchant(params);
        const categoryList = this.state.categoryList;
        this.setState(
            {
                merchantName:"",
                keywords:[""],
                categories:[{category:categoryList[0].id, subCategory:categoryList[0].subCategories[0].id}]
            }
        )
    }

    renderLocation = () =>
    {
        console.log(this.props.AddMerchantForm);
        console.log(this.props.other);
        const countries = ["Canada", "United States"];
        const selectedCountry = this.props.formData?.country || "Canada";
        const provinces = selectedCountry === "Canada" ? 
        [
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland and Labrador",
            "Northwest Territories",
            "Nova Scotia",
            "Nunavut",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Yukon"
        ] :
        [
            "Alabama",
            "Alaska",
            "AmericanSamoa",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "DistrictofColumbia",
            "Florida",
            "Georgia",
            "Guam",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "MinorOutlyingIslands",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "NewHampshire",
            "NewJersey",
            "NewMexico",
            "NewYork",
            "NorthCarolina",
            "NorthDakota",
            "NorthernMarianaIslands",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "PuertoRico",
            "RhodeIsland",
            "SouthCarolina",
            "SouthDakota",
            "Tennessee",
            "Texas",
            "U.S.VirginIslands",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "WestVirginia",
            "Wisconsin",
            "Wyoming"
        ];
        return <>
        <div className="col-lg-4">
            <DropDown
                labelText={"Country"}
                options={countries}
                name={"country"}
                className={"form-control"}
                parentname={"AddMerchantForm"}
            />
        </div>
        <div className="col-lg-4">
            <DropDown
                labelText={selectedCountry === "Canada" ? "Province" : "State"}
                options={provinces}
                name={"state"}
                className={"form-control"}
                parentname={"AddMerchantForm"}
            />
        </div>
        <div className="col-lg-4">
            <Input
                type={"text"}
                labelText={`City`}
                placeholder={`Merchant Name`}
                className={"form-control"}
                number={"false"}
                parentname={"AddMerchantForm"}
                name={"city"}
            />
          
        </div>
        </>
    };

    render(){

        return(
            <>
                <div className="row">
                    <h1>Add Merchant</h1>
                </div>
                <div className="row">
                <Input
                        type={"text"}
                        labelText={`Merchant Name`}
                        placeholder={`Merchant Name`}
                        className={"form-control"}
                        number={"false"}
                        parentname={"AddMerchantForm"}
                        name={"merchantName"}
                        newValue={this.state.merchantName}
                        onChange={this.handleNameChange}
                        />
                </div>
                <br />
                <br />
                <div className="row">
                    <h4>Location</h4>
                </div>
                <div className="row">
                    {this.renderLocation()}
                </div>
                <div className="row">
                    <h4>Categories</h4>
                </div>
                <div className="row">
                    {this.genCategoryBoxes()}
                </div>
                <br />
                <div className="row">
                    <button type="button" className="btn btn-primary"
                                onClick={() => this.addCategory()}>
                                    Add Another
                    </button>

                </div>
                <br />
                <br />
                <div className="row">
                    <h4>Matching Keywords</h4>
                </div>
                <div className="row">
                    {this.genKeywordBoxes()}
                </div>
                <div className="row">
                    <button type="button" className="btn btn-primary"
                                onClick={() => this.addKeyword()}>
                                    Add Another
                    </button>

                </div>
                <br />
                <br />
                <div className="row">
                    <button type="button" className="btn btn-primary"
                                onClick={() => this.addMerchant()}>
                                    Add Merchant
                    </button>

                </div>
            </>
        )
    }




}

const mapStateToProps = (state,ownProps) => {

    let formData = state.forms?.AddMerchantForm;

    return {
        formData,
        other: state.forms
    }

}
export default connect(mapStateToProps)(AddMerchant);